import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import useReactRouter from "use-react-router";
import { URL_IMAGE_MEDIUM, URL_IMAGE } from "../../consts/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal } from "react-bootstrap";
import {
  onConvertGender,
  onConvertScholarship,
  onConvertEducationLevel,
  onConvertGenderEn,
  convertGender,
  convertHealthStatus,
} from "../../common/super";
import Loading from "../../common/Loading";
import "./index.css";

export default function RegistrationStudentInfo() {
  const { history, location } = useReactRouter();
  const user = location?.state;
  const [show, setShow] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [rotation, setRotation] = useState(0);

  const _handleClickUpdateStudent = () => {
    history.push(`/registrationStudent`, {
      code: user?.code,
      studentType: user?.studentType,
    });
  };

  const rotateImage = () => {
    setRotation(rotation + 90); // Rotate by 90 degrees each time
  };

  if (!user) return <Loading />;
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6" style={{ marginTop: 30, float: "left" }}>
          <a
            href="#"
            style={{ color: "#057CAE" }}
            onClick={() =>
              history.push(`/cardstudent/${user?.code}`, user?.code)
            }
            rel="noreferrer"
          >
            ຂໍ້ມູນນັກສຶກສາ
          </a>{" "}
          / {user?.firstNameE ?? "-"}
        </div>
        <div className="2 col-md-6">
          <span style={{ float: "right" }}>
            <Button
              disabled={user?.statusStep == "REJECTED" ? false : true}
              style={{
                marginTop: 30,
                backgroundColor:
                  user?.statusStep == "REJECTED" ? "#057CAE" : "#F5F5F5",
                color: user?.statusStep == "REJECTED" ? "#FFFFFF" : "#D8D8D8",
                border:
                  user?.statusStep == "REJECTED"
                    ? "2px solid #057CAE"
                    : "2px solid #D8D8D8",
              }}
              onClick={_handleClickUpdateStudent}
            >
              <FontAwesomeIcon icon={faUserEdit} /> ແກ້ໄຂ
            </Button>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="card card-detial-student col-xs-12 col-sm-12 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</p>
            <img
              style={{ margin: "0 auto" }}
              src={
                "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
              }
              className="browserImage"
              alt="icon"
            />
            <div className="row">
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ລະຫັດນັກສຶກສາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.studentNumber ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຄະນະ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.faculty?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ພາກວິຊາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.department?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສາຂາວິຊາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.major?.name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.studentInfo == "EMPLOYEE"
                    ? "ພະນັກງານ/ນັກສຶກສາມາຮຽນຕໍ່"
                    : "ນັກຮຽນສາມັນມາຮຽນຕໍ່"}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ລະດັບ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user && user
                    ? onConvertEducationLevel(user?.educationLevel)
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">ຂໍ້ມູນ​ທົ່ວໄປ</p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">ຊື່</b>
              </div>
              <div className="col-6">
                <span>
                  {onConvertGender(user?.gender)} {user?.firstNameL ?? "-"}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ນາມສະກຸນ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.lastNameL ?? "-"}</span>
              </div>
              <div className="col-6">
                <b className="textStudent text-resize4">ຊື່ (ພາສາອັງກິດ)</b>
              </div>
              <div className="col-6">
                <span>
                  {onConvertGenderEn(user?.gender)} {user?.firstNameE ?? "-"}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">
                  ນາມສະກຸນ (ພາສາອັງກິດ)
                </b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.lastNameE ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ວັນເດືອນປີເກີດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.birthday ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເພດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{convertGender(user?.gender) ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສຸຂະພາບ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{convertHealthStatus(user?.healthStatus)}</span>
              </div>
              {user?.healthStatus === "BAD" ? (
                <>
                  <div className="col-6 mt-2"></div>
                  <div className="col-6 mt-2">{user?.health}</div>
                </>
              ) : (
                ""
              )}
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສະຖານະ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.maritualStatus !== "SINGLE" ? "ແຕ່ງງານແລ້ວ" : "ໂສດ"}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສັນຊາດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.nationality ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຊົນເຜົ່າ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.tribe?.trib_name ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ສາສະໜາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.religion ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເບີໂທເຮືອນ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.phoneHome ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເບີໂທລະສັບມືຖື</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.phoneMobile ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.dormitory === "YES"
                    ? "ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                    : "ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-4">
          <div className="card-body">
            <p className="text-header text-resize5">ທີ່​ຢູ່​ປະ​ຈຸ​ບັນ</p>
            <div className="row">
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ແຂວງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.province ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເມືອງ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.district ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ບ້ານ</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.address?.village ?? "-"}</span>
              </div>

              <p className="text-header text-resize5 mt-3">ສະຖານທີ່ເກີດ</p>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">
                  ເປັນນັກສຶກສາເກີດໃນຕ່າງປະເທດ
                </b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.bornAbroad === "" ? "ບໍ່ແມ່ນ" : "ແມ່ນ"}</span>
              </div>
              {user?.bornAbroad != "" ? (
                <>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ປະເທດ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.country ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.province ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ເມືອງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.district ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ບ້ານ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.village ?? "-"}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.province ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ເມືອງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.district ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ບ້ານ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.birthAddress?.village ?? "-"}</span>
                  </div>
                </>
              )}

              <p className="text-header text-resize5 mt-3">
                ກໍລະນີສຸກເສີນຕິດຕໍ່
              </p>
              <div className="col-6">
                <b className="textStudent text-resize4">ພົວພັນທ່ານ</b>
              </div>
              <div className="col-6">
                <span>{user?.contactName ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ເບີໂທຕິດຕໍ່</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.emergencyPhone ?? "-"}</span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ອາໄສຢູ່ຮ່ວມກັນ?</b>
              </div>
              <div className="col-6 mt-2">
                <span>{user?.stayTogether === true ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}</span>
              </div>
              {user?.stayTogether === true ? (
                <></>
              ) : (
                <>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.emergencyAddress.province}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ເມືອງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.emergencyAddress.district}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ບ້ານ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.emergencyAddress.village}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-6">
          <div className="card-body">
            {user && user?.studentType !== "OUT_PLAN" ? (
              <div>
                <p
                  style={{
                    fontSize: 20,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  ສຳລັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ
                </p>
                <div className="row">
                  <div className="col-6">
                    <b className="textStudent text-resize4">ທຶນແບ່ງປັນ</b>
                  </div>
                  <div className="col-6">
                    <span>
                      {onConvertScholarship(user?.scholarship) ?? "-"}
                    </span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromProvince ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກໂຮງຮຽນ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromSchool ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ສົກສຶກສາ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.semesterYear ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">
                      ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຊ
                    </b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.scholarshipAgreementNo ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ເລກທີໃບນຳສົ່ງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.transferLetterNo ?? "-"}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    fontSize: 20,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  ສຳລັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
                </p>
                <div className="row">
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກແຂວງ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromProvince ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບຈາກໂຮງຮຽນ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduateFromSchool ?? "-"}</span>
                  </div>
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ສົກສຶກສາ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.semesterYear ?? "-"}</span>
                  </div>
                </div>
              </div>
            )}
            {user?.educationLevel === "BACHELOR_CONTINUING" ? (
              <>
                <div className="row">
                  <div className="col-6 mt-2">
                    <b className="textStudent text-resize4">ຈົບສາຂາວິຊາ</b>
                  </div>
                  <div className="col-6 mt-2">
                    <span>{user?.graduatedMajor ?? "-"}</span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="card card-detial-student col-xs-12 col-sm-6 col-md-6">
          <div className="card-body">
            <p
              style={{
                fontSize: 20,
                color: "#057CAE",
                fontWeight: "bold",
              }}
            >
              ເອກະສານຄັດຕິດ
            </p>
            <div className="row">
              <div className="col-6">
                <b className="textStudent text-resize4">
                  ໄຟລຮູບ ບັດ​ປະ​ຈຳ​ຕົວ ຫຼື ສຳ​ມ​ະ​ໂນ​ຄົວ (ພຣະ: ໜັງສືສຸທິ)
                </b>
              </div>
              <div className="col-6">
                <span>
                  {user?.idCardOrFamilyBookUrl &&
                  user?.idCardOrFamilyBookUrl?.url?.split(".").pop() ===
                    "pdf" ? (
                    <a
                      target="_blank"
                      href={
                        user?.idCardOrFamilyBookUrl?.url?.startsWith("http")
                          ? user.idCardOrFamilyBookUrl.url
                          : URL_IMAGE + user.idCardOrFamilyBookUrl.url
                      }
                      //   href={URL_IMAGE + user?.idCardOrFamilyBookUrl?.url}
                      rel="noreferrer"
                    >
                      {" "}
                      ເປີດໄຟລ PDF
                    </a>
                  ) : user?.idCardOrFamilyBookUrl?.url ? (
                    <div style={{ display: "flex" }}>
                      <img
                        src={
                          user?.idCardOrFamilyBookUrl?.url?.startsWith("http")
                            ? user.idCardOrFamilyBookUrl.url
                            : URL_IMAGE + user.idCardOrFamilyBookUrl.url
                        }
                        // src={URL_IMAGE + user?.idCardOrFamilyBookUrl?.url}
                        width="40"
                        height="40"
                        alt="media"
                        onClick={() => {
                          setShow(true);
                          setImgUrl(
                            user?.idCardOrFamilyBookUrl?.url?.startsWith("http")
                              ? user.idCardOrFamilyBookUrl.url
                              : URL_IMAGE + user.idCardOrFamilyBookUrl.url
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                    </div>
                  )}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">ໄຟລຮູບ ໃບ​ປະ​ກາດ</b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.certificateUrl &&
                  user?.certificateUrl?.url?.split(".").pop() === "pdf" ? (
                    <a
                      target="_blank"
                      href={
                        user?.certificateUrl?.url?.startsWith("http")
                          ? user.certificateUrl.url
                          : URL_IMAGE + user?.certificateUrl?.url
                      }
                      rel="noreferrer"
                    >
                      {" "}
                      ເປີດໄຟລ PDF
                    </a>
                  ) : user?.certificateUrl?.url ? (
                    <div style={{ display: "flex" }}>
                      <img
                        width="40"
                        height="40"
                        alt="media"
                        onClick={() => {
                          setShow(true);
                          setImgUrl(URL_IMAGE + user?.certificateUrl?.url);
                        }}
                        src={
                          user?.certificateUrl?.url?.startsWith("http")
                            ? user.certificateUrl.url
                            : URL_IMAGE + user?.certificateUrl?.url
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                    </div>
                  )}
                </span>
              </div>
              <div className="col-6 mt-2">
                <b className="textStudent text-resize4">
                  ໄຟລຮູບ ບັດ​ເຂົ້າ​ຫ້ອງ​ເສັງ
                </b>
              </div>
              <div className="col-6 mt-2">
                <span>
                  {user?.testCardUrl &&
                  user?.testCardUrl?.url?.split(".").pop() === "pdf" ? (
                    <a
                      href={
                        user?.testCardUrl?.url?.startsWith("http")
                          ? user.testCardUrl.url
                          : URL_IMAGE + user.testCardUrl.url
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      ເປີດໄຟລ PDF
                    </a>
                  ) : user?.testCardUrl?.url ? (
                    <div style={{ display: "flex" }}>
                      <img
                        width="40"
                        height="40"
                        alt="media"
                        onClick={() => {
                          setShow(true);
                          setImgUrl(
                            user?.testCardUrl?.url?.startsWith("http")
                              ? user.testCardUrl.url
                              : URL_IMAGE + user.testCardUrl.url
                          );
                        }}
                        src={
                          user?.testCardUrl?.url?.startsWith("http")
                            ? user.testCardUrl.url
                            : URL_IMAGE + user.testCardUrl.url
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center position-relative">
          <button className="btn mb-2" onClick={rotateImage}>
            <i class="fas fa-undo"></i>
          </button>
          <img
            src={imgUrl}
            alt="student document"
            style={{
              width: "450px",
              height: "450px",
              display: "block",
              transform: `rotate(${rotation}deg)`,
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
